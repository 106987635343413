import * as React from "react"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section">
      Phone:{" "}
      <p>
        <a href="tel:0419632999">0419 632 999</a>
      </p>
      Business hours:{" "}
      <p>Monday to Friday 8:00 am to 5:30 pm (and some weekends)</p>
    </ContactStyles>
  )
}

export default Contact
